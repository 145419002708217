/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Link from '../link';

/**
 * CTA Link Component.
 *
 * @param {Object} link Link props.
 *
 * @return {jsx}
 */
const CTALink = ( { link } ) => {

	const { ctaLink } = link;

	return (
		<div className="row">
			<div className="small-12 columns">
				<Link { ...ctaLink } withArrow={ true } />
			</div>
		</div>
	);

};

CTALink.propTypes = {
	link: PropTypes.object,
};

CTALink.defaultProps = {
	link: {
		ctaLink: {}
	}
};

export default CTALink;
