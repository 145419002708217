/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Layout from '../../components/layout';
import NewsroomBanner from '../../components/newsroom/banner';
import FeatureRow from './../../components/features-row';
import SEO from "../../components/seo";
import { getOgImage } from "./../../utils/functions";
import CTALink from "./../../components/cta-link";
import ConversationAdvertForm from '../../components/conversation-based-advertising/form';
import FooterCTA from './../../components/footer-cta';

import './style.scss';

/**
 * Newsroom Template Page.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
const Newsroom = (props) => {

	const {
		pageContext: {
			page,
			page: {
				openWebForNewsroomPageMeta: {
					featureList,
					linkWrapper
				},
				contactUsFormMeta: { contactForm, ctaLink },
				seo,
				uri,
				footerCtaLinks: { footerCtaWrapper },
			}
		}
	} = props;

	const openGraphImage = getOgImage(seo);

	return (
		<Layout title="Newsroom" uri={uri} className="archive archive-single">
			<SEO
				title={page.title}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>
			<NewsroomBanner page={page} ctaLink={ctaLink} tagParent="PUBLISHERS" tagName="Newsrooms_Contact" />
			<FeatureRow meta={featureList} />
			{linkWrapper.ctaLink && <section className="newsroom-cta-link">
				<CTALink link={linkWrapper} />
			</section>}
			{footerCtaWrapper && <section className="newsroom-cta">
				<FooterCTA links={footerCtaWrapper} />
			</section>}
			<ConversationAdvertForm meta={contactForm} tagParent="PUBLISHERS" tagName="Newsrooms_Submit" />
		</Layout>
	)
};

Newsroom.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default Newsroom;
